// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.3); /* Semi-transparent background */
    z-index: 9999;
    transition: opacity 0.5s ease-out, visibility 0.5s ease-out;
    opacity: 1;
    visibility: visible;
}

.preloader-remove {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-out, visibility 0.5s ease-out;
}

.spinner {
    border: 12px solid #f3f3f3;
    border-radius: 50%;
    border-top: 12px solid #4c844d;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
`, "",{"version":3,"sources":["webpack://./src/Modules/Preloader.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,0CAA0C,EAAE,gCAAgC;IAC5E,aAAa;IACb,2DAA2D;IAC3D,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,2DAA2D;AAC/D;;AAEA;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,8BAA8B;IAC9B,WAAW;IACX,YAAY;IACZ,kCAAkC;AACtC;;AAEA;IACI,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;AACtC","sourcesContent":[".preloader {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100vw;\r\n    height: 100vh;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    background-color: rgba(255, 255, 255, 0.3); /* Semi-transparent background */\r\n    z-index: 9999;\r\n    transition: opacity 0.5s ease-out, visibility 0.5s ease-out;\r\n    opacity: 1;\r\n    visibility: visible;\r\n}\r\n\r\n.preloader-remove {\r\n    opacity: 0;\r\n    visibility: hidden;\r\n    transition: opacity 0.5s ease-out, visibility 0.5s ease-out;\r\n}\r\n\r\n.spinner {\r\n    border: 12px solid #f3f3f3;\r\n    border-radius: 50%;\r\n    border-top: 12px solid #4c844d;\r\n    width: 80px;\r\n    height: 80px;\r\n    animation: spin 2s linear infinite;\r\n}\r\n\r\n@keyframes spin {\r\n    0% { transform: rotate(0deg); }\r\n    100% { transform: rotate(360deg); }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
