// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmation-message {
    text-align: center;
    margin-top: 50px;
}

.confirmation-message h2 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.confirmation-message p {
    font-size: 16px;
    color: #666;
    margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/GoToConfirm.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".confirmation-message {\n    text-align: center;\n    margin-top: 50px;\n}\n\n.confirmation-message h2 {\n    font-size: 24px;\n    font-weight: bold;\n    color: #333;\n}\n\n.confirmation-message p {\n    font-size: 16px;\n    color: #666;\n    margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
