// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-videos-container {
    text-align: center;
    margin-top: 50px;
}

.add-button {
    font-size: 2em;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}

.add-button:hover {
    background-color: #45a049;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid lightgray;
    border-top: 5px solid #4CAF50;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/NoVideos.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,2BAA2B;IAC3B,6BAA6B;IAC7B,kBAAkB;IAClB,kCAAkC;AACtC;;AAEA;IACI,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;AACtC","sourcesContent":[".no-videos-container {\r\n    text-align: center;\r\n    margin-top: 50px;\r\n}\r\n\r\n.add-button {\r\n    font-size: 2em;\r\n    padding: 10px 20px;\r\n    background-color: #4CAF50;\r\n    color: white;\r\n    border: none;\r\n    cursor: pointer;\r\n}\r\n\r\n.add-button:hover {\r\n    background-color: #45a049;\r\n}\r\n\r\n.modal {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.modal-content {\r\n    background-color: white;\r\n    padding: 20px;\r\n    border-radius: 8px;\r\n}\r\n\r\n.spinner {\r\n    width: 50px;\r\n    height: 50px;\r\n    border: 5px solid lightgray;\r\n    border-top: 5px solid #4CAF50;\r\n    border-radius: 50%;\r\n    animation: spin 1s linear infinite;\r\n}\r\n\r\n@keyframes spin {\r\n    0% { transform: rotate(0deg); }\r\n    100% { transform: rotate(360deg); }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
